$common-font-color: #888;
$hover-font-color: #01579B;
$header-color: #01579B;
$header-secondary-color: #777;
$header-secondary-light-color: #f4f4f4;
$header-light-color: #fff;
$border-color: #f4f4f4;
$background-color: #0288D1;
$background-secondary-color: #222;
$background-light-color: #fafafa;

$focus-box-shadow: inset 0 0 0 1px #fff, 0 0 3px 1.5px #1776bb;