@import url("https://fonts.googleapis.com/css?family=Lato:400,400italic,700,700italic|Source+Code+Pro:400&display=swap");

.header,
.h2,
.h3,
.h4,
.h5 {
  font-weight: bold;
  text-transform: capitalize;
}

.h2 {
  font-size: rem-calc(24);
  line-height: 1.2;
  letter-spacing: rem-calc(1.5);
  color: $header-color;
}

.h3 {
  font-size: rem-calc(20);
  line-height: 1.3;
  letter-spacing: rem-calc(1.3);
  color: $header-secondary-color;
}

.h4 {
  font-size: rem-calc(16);
  line-height: 1.2;
  letter-spacing: rem-calc(1.3);
  color: $header-secondary-color;
}

.h5 {
  font-size: rem-calc(15);
  line-height: 1.1;
  letter-spacing: rem-calc(1.2);
  color: $header-secondary-color;
}