.sidebar-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s;
}

body.show {
  overflow: hidden;

  .sidebar-container {
    left: 0;
  }

  .sidebar-mask {
    visibility: visible;
    opacity: 0.5;
  }
}

.sidebar-container {
  background: $background-color;
  left: -#{ $sidebar-width };
  text-align: center;
  text-transform: capitalize;
  transition: left 0.4s;
  padding-top: rem-calc(40);
  overflow: auto;

  @include breakpoint(medium) {
    left: 0;
  };
}

.sidebar-image-container {
  width: rem-calc(100);
  margin: auto;
}

.sidebar-image {
  border-radius: 50%;
}

.sidebar-header {
  margin-top: rem-calc(30);
}

.sidebar-title {
  color: $header-light-color;
  text-align: center;
}

.sidebar-secondary-header {
  margin-top: rem-calc(20);
  font-size: rem-calc(16);
  font-style: italic;
  color: $header-secondary-light-color;
}

.section-links {
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(50);
}

.section-link {
  display: block;
  padding: rem-calc(20 0);
  color: $header-light-color;
  font-weight: bold;
  transition: background 0.4s, color 0.4s;

  &:not(:first-of-type) {
    border-top: 1px solid $border-color;
  }

  &:active,
  &:hover {
    color: $header-color;
    background: $background-light-color;
  }
}