.project-image-container,
.project-details {
  flex: 1 1 50%;
}

.project-image-container {
  display: block;

  img {
    border-radius: 5px;
  }
}

.project-details {
  position: relative;
}

.project-links {
  display: flex;
}

.project-link {
  margin: auto;
}

@include breakpoint(medium) {
  .project-container {
    display: flex;
    align-items: center;
  }

  .project-image {
    margin-bottom: 0;
  }

  .project-details {
    margin-left: rem-calc(20);
  }
};