.page-header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $background-secondary-color;
}

.page-header-spacer,
.page-header-container {
  height: $page-header-height;

  @include breakpoint(medium) {
    display: none;
  };
}

.sidebar-trigger-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: $page-header-height;
  height: 100%;
  background: $background-color;
  border: none;

  @include breakpoint(medium) {
    display: none;
  };
}

.header-title {
  padding: rem-calc(10 0);
  font-size: rem-calc(17);
  letter-spacing: rem-calc(1);
  color: $header-light-color;
  text-align: center;
}